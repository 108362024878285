define("api/models/fragment-credential-library-attributes", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-data-model-fragments/fragment", "@ember-data/model", "api/models/credential-library"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fragment, _model, _credentialLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let FragmentCredentialLibraryAttributesModel = (_dec = (0, _model.attr)('string', {
    description: 'The HTTP method the library uses when requesting credentials from Vault.',
    defaultValue: () => _credentialLibrary.options.http_method[0]
  }), _dec2 = (0, _model.attr)('string', {
    description: 'The body of the HTTP request the library sends to Vault when requesting credentials. Only valid if `http_method` is set to `POST`.'
  }), _dec3 = (0, _model.attr)('string', {
    description: 'The path in Vault to request credentials from.'
  }), (_class = class FragmentCredentialLibraryAttributesModel extends _fragment.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "http_method", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "http_request_body", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "path", _descriptor3, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "http_method", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "http_request_body", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "path", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FragmentCredentialLibraryAttributesModel;
});