define("core/components/form/authenticate/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @model.type}}
    {{component (concat "form/authenticate/" @model.type)
      submit=@submit
      cancel=false
      disabled=@disabled
    }}
  {{/if}}
  
  */
  {
    "id": "vYKw1pqC",
    "block": "[[[41,[30,1,[\"type\"]],[[[1,\"  \"],[46,[28,[37,2],[\"form/authenticate/\",[30,1,[\"type\"]]],null],null,[[\"submit\",\"cancel\",\"disabled\"],[[30,2],false,[30,3]]],null],[1,\"\\n\"]],[]],null]],[\"@model\",\"@submit\",\"@disabled\"],false,[\"if\",\"component\",\"concat\"]]",
    "moduleName": "core/components/form/authenticate/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});