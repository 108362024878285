define("api/serializers/fragment-credential-library-attributes", ["exports", "@ember-data/serializer/json"], function (_exports, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FragmentCredentialLibraryAttributesSerializer extends _json.default {
    /**
     * If an attribute is annotated as readOnly in the model, don't serialize it.
     * Otherwise delegate to default attribute serializer.
     * Only serialize http_request_body when `http_method` is set to `POST`.
     * @override
     * @method serializeAttribute
     * @param {Snapshot} snapshot
     * @param {Object} json
     * @param {String} key
     * @param {Object} attribute
     */
    serializeAttribute(snapshot, json, key, attribute) {
      const {
        type,
        options
      } = attribute;
      let value = super.serializeAttribute(...arguments); // Convert empty strings to null.

      if (type === 'string' && json[key] === '') json[key] = null; // Do not serialize read-only attributes.

      if (options.readOnly) delete json[key]; // Do not serialize http_request_body unless http_method is POST

      if (key === 'http_request_body' && !json['http_method']?.match(/post/i)) delete json[key];
      return value;
    }

  }

  _exports.default = FragmentCredentialLibraryAttributesSerializer;
});