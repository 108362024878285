define("api/models/fragment-managed-group-attributes", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let FragmentManagedGroupAttributesModel = (_dec = (0, _model.attr)('string', {
    description: 'The boolean expression filter to use to determine membership.'
  }), (_class = class FragmentManagedGroupAttributesModel extends _fragment.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "filter", _descriptor, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filter", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FragmentManagedGroupAttributesModel;
});