define("api/serializers/fragment-managed-group-attributes", ["exports", "@ember-data/serializer/json"], function (_exports, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FragmentManagedGroupAttributesSerializer extends _json.default {
    /**
     * If an attribute is annotated as readOnly in the model, don't serialize it.
     * Otherwise delegate to default attribute serializer.
     * @override
     * @method serializeAttribute
     * @param {Snapshot} snapshot
     * @param {Object} json
     * @param {String} key
     * @param {Object} attribute
     */
    serializeAttribute(snapshot, json, key, attribute) {
      const {
        type,
        options
      } = attribute;
      let value = super.serializeAttribute(...arguments); // Convert empty string to null.

      if (type === 'string' && json[key] === '') json[key] = null; // Do not serialize read-only attributes.

      if (options.readOnly) delete json[key]; // Version is sent only if it has a non-nullish value

      if (key === 'version') {
        if (json[key] === null || json[key] === undefined) delete json[key];
      }

      return value;
    }

  }

  _exports.default = FragmentManagedGroupAttributesSerializer;
});