define("api/serializers/credential-library", ["exports", "api/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CredentialLibrarySerializer extends _application.default {}

  _exports.default = CredentialLibrarySerializer;
});