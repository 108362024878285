define("api/models/account", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/account", "ember-data-model-fragments/attributes", "@ember/object", "@ember/object/computed"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _account, _attributes, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let AccountModel = (_dec = (0, _attributes.fragment)('fragment-account-attributes', {
    defaultValue: {}
  }), _dec2 = (0, _computed.equal)('type', 'password'), _dec3 = (0, _computed.equal)('type', 'oidc'), _dec4 = (0, _object.computed)('attributes.{email,full_name,login_name}'), (_class = class AccountModel extends _account.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "attributes", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "isPassword", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "isOIDC", _descriptor3, this);
    }

    /**
     * Convenience for getting username in account.
     * @type {string}
     */
    get accountName() {
      const {
        email,
        full_name,
        login_name
      } = this.attributes;
      return email || full_name || login_name;
    } // =methods

    /**
     * Save account password via the `set-password` method.
     * See serializer and adapter for more information.
     * @param {string} password
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    setPassword(password) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'set-password',
        password
      };
      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Update account password via the `change-password` method.
     * See serializer and adapter for more information.
     * @param {string} currentPassword
     * @param {string} newPassword
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    changePassword(currentPassword, newPassword) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'change-password',
        currentPassword,
        newPassword
      };
      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isPassword", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isOIDC", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountName", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "accountName"), _class.prototype)), _class));
  _exports.default = AccountModel;
});