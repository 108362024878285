define("api/models/managed-group", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/managed-group", "@ember-data/model", "ember-data-model-fragments/attributes", "@ember/array"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _managedGroup, _model, _attributes, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let ManagedGroupModel = (_dec = (0, _model.attr)('string', {
    readOnly: true
  }), _dec2 = (0, _model.attr)({
    readOnly: true,
    defaultValue: () => (0, _array.A)(),
    emptyArrayIfMissing: true
  }), _dec3 = (0, _attributes.fragment)('fragment-managed-group-attributes', {
    defaultValue: {}
  }), (_class = class ManagedGroupModel extends _managedGroup.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "attributes_filter", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "member_ids", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "attributes", _descriptor3, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_filter", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "member_ids", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ManagedGroupModel;
});