define("api/models/credential-store", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/credential-store", "@ember-data/model", "ember-data-model-fragments/attributes", "@ember/object/computed"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _credentialStore, _model, _attributes, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  let CredentialStoreModel = (_dec = (0, _model.attr)('string', {
    readOnly: true
  }), _dec2 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec3 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec4 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec5 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec6 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec7 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec8 = (0, _model.attr)('string', {
    readOnly: true
  }), _dec9 = (0, _attributes.fragment)('fragment-credential-store-attributes', {
    defaultValue: {}
  }), _dec10 = (0, _computed.equal)('type', 'vault'), (_class = class CredentialStoreModel extends _credentialStore.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "attributes_address", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_namespace", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_ca_cert", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_tls_server_name", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_tls_skip_verify", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_token", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_client_certificate", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "attributes_client_certificate_key", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "attributes", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "isVault", _descriptor10, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_address", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_namespace", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_ca_cert", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_tls_server_name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_tls_skip_verify", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_token", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_client_certificate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes_client_certificate_key", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "attributes", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isVault", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CredentialStoreModel;
});